import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const IndexPage = () => (
  <div id="indexPage">
    <Header/>

    <div className="content-pad">
      <h2>Postuler à FISH MED NET</h2>
      <p>
        Le projet FISH MED NET a été conçu et réalisé dans l'intention de créer un environnement collaboratif entre les communautés de pêcheurs de 
        Méditerranée. Dans le cadre, l'initiative, FISH MED NET est à la disposition de toutes les micros, petites et moyennes entreprises qui 
        souhaitent participer à ce projet. L'entreprise bénéficiera de fait d'un référencement et publication sur 
        la plateforme électronique du projet. Ainsi, elle aura une visibilité et un contact direct avec un public à la fois l'échelle locale et internationale. 
      </p>
      <p>
        Pour participer à cette initiative, il suffit simplement d'adhérer aux conditions définies dans le projet. Ces lignes directrices visent à 
        respecter l'environnement et le statut socio-économique dans lequel ils sont impliqués localement. Les conditions figurent dans l'accord 
        d'alliance commerciale (qui est un accord libre et gratuit n) qui sera remis lorsqu'une entreprise souhaite participer à cette initiative.
      </p>
      <p>
        En adhérant et en respectant ces conditions, FISH MED NET vise à créer un monde meilleur!
      </p>

      <h2>Comment postuler?</h2>
      Afin d'enregistrer votre intérêt, veuillez:
      <ul>
        <li>Contactez votre bureau de conseil respectif, le plus proche, pour manifester et faire prendre en compte votre intérêt.</li>
        <li>Nous vous contacterons, par la suite ensuite, pour vous demander le statut de votre entreprise enregistrée dans votre pays.</li>
        <li>Une fois que tous les documents vérifiés, nous vous fournissons:</li>
        <ul>
          <li>Un formulaire à remplir qui permettra de recueillir des informations de base liées uniquement à votre activité professionnelle</li>
          <li>Un Accord d'alliance commerciale qui exprime votre volonté de respecter et d'adhérer aux règles mentionnées. Ce document devra être signé et renvoyé au bureau conseil</li>
          <li>Une fois que tous les documents reçus par le bureau compètent, l'entreprise sera intégrée à la plateforme du projet</li>
        </ul>
      </ul>

      <h2>Nos guichets conseils</h2>
      <table>
        <tr>
          <th>Country</th><th>Region</th><th>Address</th><th>Working hours</th><th>Email</th>
        </tr>
        <tr>
          <td>France</td><td>Corsica</td><td>-</td><td>-</td><td><a href="mailto:FMNCorsicaFR@gmail.com">FMNCorsicaFR@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Liguria</td><td>-</td><td>-</td><td><a href="mailto:fmnliguria@gmail.com">fmnliguria@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Puglia</td><td>-</td><td>-</td><td><a href="mailto:fmnpugliait@gmail.com">fmnpugliait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Italy</td><td>Sardegna</td><td>-</td><td>-</td><td><a href="mailto:fmnsardegnait@gmail.com">fmnsardegnait@gmail.com</a></td>
        </tr>
        <tr>
          <td>Tunisia</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNTunisieTN@gmail.com">FMNTunisieTN@gmail.com</a></td>
        </tr>
        <tr>
          <td>Lebanon</td><td></td><td>-</td><td>-</td><td><a href="mailto:FMNLebanonlb@gmail.com">FMNLebanonlb@gmail.com</a></td>
        </tr>
        <tr>
          <td>Palestine</td><td></td><td>-</td><td>-</td><td><a href="mailto:fmngazapal@gmail.com">fmngazapal@gmail.com</a></td>
        </tr>
      </table>

      <h2>Explorez parmi les dernières propositions</h2>
    </div>
    <Explore slug="index" />

    <Footer/>
  </div>
)

export default IndexPage
